import * as React from "react"
import { useEffect } from 'react';
import { Link } from "gatsby"

import Layout from "../../../templates/layout-standard"
import Seo from "../../../components/seo"
import SideBar from '../../../components/sidebar';
import Turning65Nav from '../../../components/nav/Turning65';
import UpNextCardNonStrapi from '../../../components/ui/UpNextCardNonStrapi';
import LearnMore from '../../../components/learnmore';
import SpeakWithAdvisor from '../../../components/ui/SpeakWithAdvisor';

function toggleAccordion(event) {
	let accordionContainer = this.nextElementSibling;
	this.classList.toggle("open");
	if (this.classList.contains('open')) {
		accordionContainer.setAttribute("aria-hidden", "false");
	} else {
		accordionContainer.setAttribute("aria-hidden", "true");
	}

	if (!accordionContainer.classList.contains('open')) {
		accordionContainer.classList.add('open');
		accordionContainer.style.height = 'auto';
	
		let height = accordionContainer.clientHeight + 'px';
	
		accordionContainer.style.height = '0px';
	
		setTimeout(function () {
			accordionContainer.style.height = height;
		}, 0);
	} else {
		accordionContainer.style.height = '0px';		
		accordionContainer.addEventListener('transitionend', function () {
			accordionContainer.classList.remove('open');
		}, {
			once: true
		});
	}
}

const Turning65When = ({ location }) => {
	useEffect(() => {
		document.querySelectorAll(".accordiontrigger").forEach(trigger => {
			trigger.removeEventListener('click', toggleAccordion);
			trigger.addEventListener('click', toggleAccordion);
		});
	});

	return (
		<Layout location={location} heading1="Turning 65" heading2="Let&rsquo;s get you ready for Medicare.">
		<Seo title="Know What To Do When | Turning 65 | Medicare Basics" meta={[{description: 'Learn how to make the most of the various Medicare plan options available to you.'}]} bodyclass="medicare-basics section1 subpage" />
		<Turning65Nav />
				
		<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">

						<main>
							<h3>Know what to do when</h3>
							
							<p>As soon as you turn 64, you should start getting ready for Medicare. If you&rsquo;ve decided to enroll in Medicare, this timeline offers suggestions for what you need to do to stay on track.</p>
							
							<div className="whatwhen-explained">
								<h4>Age 64</h4>
								
								<div className="accordion">
									<div className="accordion-item">
										<button type="button" id="accordion-trigger-01" className="accordiontrigger" aria-controls="accordion-content-01">
											You can do this!
										</button>
										<div id="accordion-content-01" className="accordion-content" aria-labelledby="accordion-trigger-01" aria-hidden="true">
											<div className="contentwrap">
												<ul>
													<li>Learn about Medicare and the different types of plans. <Link to="/medicare-basics/turning-65/learn-how-medicare-works/">Start here</Link>.</li>
													<li>Make a list of your regular doctors and prescription drugs to make it easy for you to compare plans.</li>
													<li>If you currently have coverage through an employer, ask if they offer coverage options for people 65 and older.</li>
													<li>Get a picture of your health care history. Ask yourself the following questions: 
														<ul>
															<li>What did you pay in the past year? Look at your TOTAL costs, including premiums, deductibles, copays and coinsurance.</li>
															<li>How many times did you go to the doctor?</li>
															<li>How many hospital stays did you have?</li>
															<li>Do you plan on traveling?</li>
															<li>Knowing these details will make it easier to find the right plan when you are ready to enroll.</li>
														</ul>
													</li>
													<li>Compare different Medicare plans to find the coverage and premiums that work best for you.
														<ul>
															<li>Medicare Star Quality Ratings offer an objective way to compare Medicare health plans. <Link to="/about/star-rating/">Learn more about Medicare Star Ratings</Link>.</li>
															<li>When comparing plans, be sure to look at the maximum amount of costs you could incur, or the out-of-pocket maximum.</li>
															<li><Link to="/find-a-doctor/">See if your doctors are covered</Link>.</li>
															<li><Link to="/rx-information/">See if your prescriptions are covered</Link>.</li>
															<li><Link to="/plans/">Compare UVM Health Advantage plans online</Link>.</li>
															<li><Link to="/meet-with-us/">Speak to a Plan Guide</Link>.</li>
															<li><Link to="/plans/benefits-savings-support/">Find out about any extra benefits</Link>, such as fitness programs, hearing aid discounts and more.</li>
														</ul>
													</li>
													<li><Link to="/meet-with-us/">Arrange a personal consultation</Link> with a UVM Health Advantage Plan Guide.</li>
													<li>Determine which options are best for you.</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<h4>Age 64 +9 months</h4>
								<div className="accordion">
									<div className="accordion-item">
										<button type="button" id="accordion-trigger-02" className="accordiontrigger" aria-controls="accordion-content-02">
											Now&rsquo;s the time to choose your plan.
										</button>
										<div id="accordion-content-02" className="accordion-content" aria-labelledby="accordion-trigger-02" aria-hidden="true">
											<div className="contentwrap">
												<ul>
													<li>Time to enroll. You can start enrolling in Medicare starting three months before the month of your birthday.</li>
													<li>To enroll in Part A and Part B, contact the <a href="https://www.ssa.gov/" target="_blank" rel="noreferrer noopener">Social Security Administration</a>.</li>
													<li>Ready to enroll in a UVM Health Advantage (Part C) plan? <Link to="/enroll-now/">We make it easy</Link>.</li>
												</ul>
												<p><b>Need help picking a plan?</b> <a href="https://bestplanforme.mvphealthcare.com/?sourcesite=uvm">Get a personalized plan recommendation</a></p>
												<p><i>If you haven&rsquo;t already done so, <Link to="/plans/">you should compare plans to determine which best meets your needs</Link> or <Link to="#">request a Plan Benefit Kit</Link>.</i></p>
											</div>
										</div>
									</div>
								</div>
								
								<h4>Age 65</h4>
								
								<div className="accordion">
									<div className="accordion-item">
										<button type="button" id="accordion-trigger-03" className="accordiontrigger" aria-controls="accordion-content-03">
											Let&rsquo;s get started!
										</button>
										<div id="accordion-content-03" className="accordion-content" aria-labelledby="accordion-trigger-03" aria-hidden="true">
											<div className="contentwrap">
												<ul>
													<li><b>You can now take advantage of Medicare benefits.</b> If you already enrolled in Medicare, it becomes effective now.</li>
												</ul>
												<p><b>Need help picking a plan?</b> <a href="https://bestplanforme.mvphealthcare.com/?sourcesite=uvm">Get a personalized plan recommendation</a></p>
												<p><i>If you are still shopping for an individual Medicare plan, you only have three more months to do so.  
													Have questions? <Link to="/contact-us/">UVM Health Advatage is here to help</Link>.</i></p>
											</div>
										</div>
									</div>
								</div>


								<h4>Age 65 +3 months</h4>

								<div className="accordion">
									<div className="accordion-item">
										<button type="button" id="accordion-trigger-04" className="accordiontrigger" aria-controls="accordion-content-04">
											Don&rsquo;t get stuck paying penalties
										</button>
										<div id="accordion-content-04" className="accordion-content" aria-labelledby="accordion-trigger-04" aria-hidden="true">
											<div className="contentwrap">
												<ul>
													<li><b>You must be enrolled</b> in Part A, B and D (or creditable coverage) by now to avoid paying late enrollment penalties.</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<h4>Beyond Age 65</h4>

								<div className="accordion">
									<div className="accordion-item">
										<button type="button" id="accordion-trigger-05" className="accordiontrigger" aria-controls="accordion-content-05">
											Medicare is not a &ldquo;once and done&rdquo; decision.
										</button>
										<div id="accordion-content-05" className="accordion-content" aria-labelledby="accordion-trigger-05" aria-hidden="true">
											<div className="contentwrap">
												<ul>
													<li><b>You can change plans once a year.</b> If the Medicare plan you chose is not meeting your needs, you can change plans, typically in the fall, during the Medicare Annual Enrollment Period (AEP).</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							<p>Plan to work past 65? <Link to="../../65-plus/where-to-start/">Explore your options</Link></p>

							<UpNextCardNonStrapi to="/medicare-basics/turning-65/tips-for-your-situation/">Tips For Your Situation</UpNextCardNonStrapi>
							<LearnMore />
							<SpeakWithAdvisor />
						</main>
						
						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Turning65When
